import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import Stepper1 from "../../components/OnBoarding/Step1";
import Stepper2 from "../../components/OnBoarding/Step2";
import { useForm, FormProvider } from "react-hook-form";
import Stepper3 from "../../components/OnBoarding/Step3";
import { useNavigate } from "react-router-dom";
import Welcome from "../../components/OnBoarding/Welcome";
import { useDispatch } from "react-redux";
import { setFreedata, updateUserData } from "../../redux/Reducer/userReducer";
import "./styles.scss";
import { UserService } from "../../services/user.service";

const OnBoardingPage = () => {
  const userService = new UserService();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const methods = useForm({
    mode: "onChange",
  });
  const [step, setStep] = useState<number>(0);
  const onSubmit = (data: any) => {
    if (step < 3) {
      setStep((prev) => prev + 1);
    } else {
      dispatch(setFreedata({ loginSource: "login" }));
      navigate("/analyser");
    }
  };

  const stepRenders = () => {
    switch (step) {
      case 0:
        return <Welcome onNext={() => setStep((pre) => pre + 1)} />;
      case 1:
        return <Stepper1 backClick={() => setStep((prev) => prev - 1)} />;
      case 2:
        return <Stepper2 backClick={() => setStep((prev) => prev - 1)} />;
      case 3:
        return <Stepper3 backClick={() => setStep((prev) => prev - 1)} />;
      default:
        break;
    }
  };

  const getUserDetails = async () => {
    const data = await userService.GetUser();
    if (!!data.data) dispatch(updateUserData(data.data));
  };

  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className="onboarding-container">
      <Box className="background" />
      <FormProvider {...methods}>
        <form
          className="onboarding-form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {stepRenders()}
        </form>
      </FormProvider>
    </Box>
  );
};

export default OnBoardingPage;
