import React from 'react';
import { ReactComponent as FileIcon } from '../../../assets/light/PageOrange.svg';
import { motion } from 'framer-motion';
import './styles.scss';
import SequentialTypeWriter from '../../Typewriter/sequential';

interface IProps {
    data: any;
}

const AnalyseView: React.FC<IProps> = ({ data }) => {
    const containerVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { staggerChildren: 0.4 } },
    };
    
    const Typewriter = SequentialTypeWriter();

    return (
        <motion.div
            className="analyze-view-container"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
        >
            <div className="file-container">
                <FileIcon />
                <div className="file-details">
                    <Typewriter index={0} className="file-name">
                        {data?.file?.name}
                    </Typewriter>
                    <Typewriter index={1} className="file-size">
                        {`${(data?.file?.size / 1000)}  KB - 100% uploaded`}
                    </Typewriter>
                </div>
            </div>

            <div className="view-cotainer"  >
                <Typewriter index={2} className="header">
                    {data?.analysisReport?.title}
                </Typewriter>
                <div className="inner-content">
                    <Typewriter index={3} className="inner-header">
                        {data?.analysisReport?.message}
                    </Typewriter>
                    <Typewriter index={4} className="exp-content">
                        {data?.analysisReport?.experience}
                    </Typewriter>
                </div>

                <div className="study-details">
                    <Typewriter index={5} className="study-header">
                        STUDY RECOMMENDATION
                    </Typewriter>
                    <Typewriter className="inner-header" index={6}>
                        {data?.analysisReport?.studyRecommendation?.content}
                    </Typewriter>
                    <Typewriter className="exp-content" index={7}>
                        {data?.analysisReport?.studyRecommendation?.text}
                    </Typewriter>
                </div>

                <div className="study-details">
                    <Typewriter index={8} className="study-header">
                        PERFORMANCE
                    </Typewriter>
                    <div className="performance-content"></div>
                </div>

                <div className="study-details">
                    <Typewriter index={9} className="study-header">
                        AVERAGE TIME SPENT ON QUESTION
                    </Typewriter>
                    <Typewriter index={10} className="base-value">
                        {data?.analysisReport?.performance?.averageTimeSpentOnQuestion?.value}
                    </Typewriter>
                    <Typewriter index={11} className="exp-content">
                        {data?.analysisReport?.performance?.averageTimeSpentOnQuestion?.comment}
                    </Typewriter>
                </div>

                <div className="study-details">
                    <Typewriter index={12} className="study-header">
                        ACCURACY LEVEL
                    </Typewriter>
                    <Typewriter index={13} className="base-value">
                        {data?.analysisReport?.performance?.accuracyLevel?.value}
                    </Typewriter>
                    <Typewriter index={14} className="exp-content">
                        {data?.analysisReport?.performance?.accuracyLevel?.comment}
                    </Typewriter>
                </div>
            </div>
        </motion.div>
    );
};

export default AnalyseView;
