import React, { useEffect } from "react";
import "./styles.scss";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import LoginForm from "../loginPage";
import OnBoardingPage from "../onBoardingPage";
import SideLayout from "../../layout/sideMenu";
import { useDispatch, useSelector } from "react-redux";
import Analyser from "../analyser";
import HeaderLayout from "../../layout/header";
import Dashboard from "../dashboard";
import StudyPlan from "../studyPlan";
import Profile from "../profile";
import { updateUserData } from "../../redux/Reducer/userReducer";
import { UserService } from "../../services/user.service";
import ResetPassword from "../resetPassword";

const AppRoutes: React.FC = () => {
  const userService = new UserService();
  const dispatch = useDispatch();
  const { isAuthenticated, loginSource } = useSelector(
    (state: any) => state?.userDetails
  );

  const getUserDetails = async () => {
    const data = await userService.GetUser();
    if (!!data?.data) dispatch(updateUserData(data.data));
  };

  useEffect(() => {
    if (isAuthenticated) getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <div className="landing-page">
      <Routes>
        {/* Authenticated Routes */}
        <Route
          path="/"
          element={isAuthenticated ? <Outlet /> : <Navigate to={"/sign-in"} />}
        >
          <Route
            path="/"
            element={
              loginSource === "login" ? (
                <Navigate to={"/dashboard"} />
              ) : (
                <Navigate to={"/onboarding"} />
              )
            }
          />
          <Route path="/" element={<SideLayout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/analyser" element={<Analyser />} />
            <Route path="/studyPlan" element={<StudyPlan />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/reports" element={<></>} />
          </Route>
          <Route path="/" element={<HeaderLayout auth={false} />}>
            <Route path="/onboarding" element={<OnBoardingPage />} />
          </Route>
          <Route path="/*" element={<Navigate to={"/dashboard"} />} />
        </Route>
        {/* Unauthenticated Routes */}
        <Route
          path="/"
          element={
            !isAuthenticated ? (
              <Outlet />
            ) : loginSource === "login" ? (
              <Navigate to={"/dashboard"} />
            ) : (
              <Navigate to={"/onboarding"} />
            )
          }
        >
          <Route path="/" element={<Navigate to={"/sign-in"} />} />
          <Route path="/" element={<HeaderLayout auth={true} />}>
            <Route path="/sign-in" element={<LoginForm page={"signIn"} />} />
            <Route path="/sign-up" element={<LoginForm page={"signUp"} />} />
            <Route path="/forgot-password" element={<LoginForm page={"forgotPassword"} />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Route>
          <Route path="/*" element={<Navigate to={"/"} />} />
        </Route>
      </Routes>
    </div>
  );
};

export default AppRoutes;
